@import url(~slick-carousel/slick/slick.css);
@import url(~@fortawesome/fontawesome-pro/css/all.min.css);
html, body {
  font-size: 14px;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  background-color: white; }

.page-padding-bottom {
  padding-bottom: 4rem; }

/* GOOGLE MAPS STYLES */
a[href^="http://maps.google.com/maps"] {
  display: none !important; }

a[href^="https://maps.google.com/maps"] {
  display: none !important; }

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none; }

.pac-container {
  z-index: 1350 !important; }

.slick-slide > div > div {
  outline: none; }

.slick-initialized {
  max-width: 300px;
  margin: auto; }
